<template>
  <NamiModal
    :modelValue="modelValue"
    @update:modelValue="(v) => emit('update:modelValue', v)"
  >
    <div class="space-y-4">
      <h2 class="text-lg font-medium text-center">
        Report this {{ props.entityType }}
      </h2>
      <div>
        <NamiTextArea v-model="message" label="Enter a reason..." block />
      </div>
      <div class="flex justify-end">
        <NamiButton buttonType="primary" @buttonClick="report">
          Submit
        </NamiButton>
      </div>
    </div>
  </NamiModal>
</template>

<script setup lang="ts">
import { Report } from "~/src/api";

const props = defineProps<{
  modelValue: boolean;
  entityType: Parameters<typeof Report.create>[0]["entityType"];
  entityId: Parameters<typeof Report.create>[0]["entityId"];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", v: boolean): void;
}>();

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();

const message = ref("");

async function report() {
  emit("update:modelValue", false);
  const token = await authStore?.getToken();

  if (!token) throw new Error("Not logged in.");

  await Report.create(
    {
      entityType: props.entityType,
      entityId: props.entityId,
      message: message.value,
    },
    token,
  );

  message.value = "";
}
</script>
